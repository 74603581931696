/* eslint-disable no-multi-str */
import { useState } from 'react'

const Faq = () => {
  const [faqs, setFaqs] = useState([
    {
      question: 'Is there a community discord?',
      answer: `We will be opening an official community discord post-mint!`,
      open: false,
    },
    {
      question: 'What is the mint price and total supply?',
      answer:
        'Final details regarding the collection will be officially announced soon. Keep your eye on our Twitter!      ',
      open: false,
    },
    {
      question: 'What are your future plans??',
      answer:
        'For us, community is the priority and at the forefront of every decision we make. In the future, the community and artwork will drive this project, and we will deliver everything we can to make this the best experience for everyone involved.      ',
      open: false,
    },
    {
      question: 'When will we meet the team?',
      answer: `This is one of our most frequently asked questions. We will not be doxing yet 
      as some members of the team are shy, but we will all dox together if this changes in the 
      future. We can however share that we are a team of 8 members who will do everything we can
       to build out our vision, and we hope you join us on this journey.`,
      open: false,
    },
    {
      question: 'What is the vision for this project?',
      answer:
        'We hope that everyone can search for themselves in our collection and find that perfect \
         PFP to identify with. Also, we may or may not have some exciting plans that we cannot wait \
         to share with you all. We would spill the beans now, but what’s the fun in that? ',
      open: false,
    },
    {
      question: 'Why is the Twitter intern so weird?',
      answer:
        'Honestly, we have no idea. Also, he’s even weirder in real life. ',
      open: false,
    },
  ])

  const clickHandler = (index) => {
    let newFaqs = [...faqs]
    newFaqs[index].open = !newFaqs[index].open
    setFaqs(newFaqs)
  }

  return (
    <div
      className="bg-[#262B44] md:py-4 md:px-4 text-white font-freepix"
      id="faq"
    >
      <div className="flex justify-center py-8 items-center tracking-[3px] text-[200%] font-upheaval text-white">
            FAQ
          </div>
      {faqs &&
        faqs.map((faq, index) => (
          <div className="flex justify-center md:mt-6" key={index}>
            <div
              className="border-4 border-black md:w-2/3 w-5/6 p-4 m-4 mb-4 cursor-pointer bg-white rounded-2xl hover:bg-white/70"
              onClick={() => clickHandler(index)}
            >
              <div className="md:text-xl text-lg font-extrabold m-4 text-black ">
                {faq.question}
              </div>
              <div
                className={`${
                  !faq.open && 'hidden'
                } text-black md:text-lg text-md m-4 duration-300 text-justify font-freepix tracking-wide`}
              >
                {faq.answer}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default Faq
