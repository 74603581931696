import React from 'react'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Content from '../components/Content'
import Gallery from '../components/Gallery'
import Faq from '../components/Faq'
import Nav1 from '../components/Nav1'
import Player from '../components/Player'
import Draggable from 'react-draggable'

const Home = () => {
  return (
    <div>
      <Nav1 />
      <Hero />
      <Content />
      <Gallery />
      <Faq />
      {/* <Footer /> */}
      <Player />
    </div>
  )
}

export default Home
