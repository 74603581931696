import { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useClickAway } from 'react-use'
import bgju from '../assets/images/bgclean.gif'
import miniMap from '../assets/images/minimap.gif'
import mapOpen from '../assets/images/mapOpen(2).png'
import trashbin from '../assets/images/trashbin.png'
import bustop from '../assets/images/bustop.png'
import signBubble from '../assets/images/signrev.png'
import trashBubble from '../assets/images/trash.png'

const Hero = () => {
  const openingMap = useRef()
  const openBubble = useRef()
  const [openMap, setOpenMap] = useState()
  const [openSign, setOpenSign] = useState()
  const [openBin, setOpenBin] = useState()

  const mapHandler = () => {
    setOpenMap(!openMap)
  }

  const signHandler = () => {
    setOpenSign(true)
  }

  const binHandler = () => {
    setOpenBin(true)
  }

  useClickAway(openingMap, () => {
    setOpenMap(false)
  })

  useClickAway(openBubble, () => {
    setOpenSign(false)
    setOpenBin(false)
  })

  return (
    <div className="pt-[60px]" id="hero">
      <div
        className="md:w-full w-full relative"
        // style={{ backgroundImage: `url(${bgju})` }}
      >
        {/* <img alt='' src={logo} className='absolute top-[30%] bg p-2 bg-[#444C67] border-[6px] border-[#8B9BB4] rounded-md left-1/2 -translate-x-1/2 w-[35%]' /> */}
        <img alt="" src={bgju} className="w-full" />
        <div
          onClick={mapHandler}
          className="absolute z-1 top-[51%] right-[19%] w-[14%] hover:scale-[105%] transition-all duration-200 "
        >
          <img alt="" src={miniMap} className="w-full" />
        </div>
        <div ref={openBubble}>
          <div
            onClick={binHandler}
            className="absolute z-2 top-[69%] right-[15.5%] w-[6%] hover:scale-[105%] transition-all duration-200"
          >
            <img alt="" src={trashbin} />
          </div>
          {openBin && (
            <div className="absolute bottom-[30%] right-[22%] lg:w-[30%] md:w-[29%] w-[30%] z-5 hover:scale-[103%] transition-all duration-200 ">
              <img alt="" src={trashBubble} className="p" />
            </div>
          )}
        </div>
        <div className="" ref={openBubble}>
          <div
            onClick={signHandler}
            className="absolute z-3 w-[12%] top-[41%] left-[19%] hover:scale-[103%] transition-all duration-200 "
          >
            <img alt="" src={bustop} />
          </div>
          {openSign && (
            <div className="absolute z-5 top-[25%] left-[31%] w-[25%] hover:scale-[103%] transition-all duration-200">
              <img alt="" src={signBubble} />
            </div>
          )}
        </div>
        {/* {openMap && <div>Opened modal</div>} */}
        {openMap && (
          <div
            className="fixed z-10
           top-[20%] left-1/2 backdrop-blur-sm -translate-x-1/2 w-[60%] p-6 bg-white/50 rounded-xl"
            ref={openingMap}
          >
            <img alt="" className="" src={mapOpen} />
          </div>
        )}
        {/* <Link to='/mint' target="_blank">
          <button className="absolute flex items-center justify-center top-[35%] text-black font-pixel12 left-1/2 md:text-[120%] text-[10px] -translate-x-1/2 z-3 bg-green-400 hover:tracking-[2px] duration-200 w-[25%] h-[6%] rounded-md py-2">
            MINT NOW
          </button>
        </Link> */}
        {/* <Link to='/mint'>
          <button className="bg-green-200 md:py-3 py-1 md:px-14 px-2 md:rounded-lg hover:text-white rounded-md md:text-2xl text-sm font-pixel12 hover:scale-105 duration-300 hover:bg-green-400 hover:border-green ">
            MINT NOW!
          </button>
        </Link> */}
      </div>
    </div>
  )
}

export default Hero
