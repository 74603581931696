/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import artist from '../assets/pfp/artist.gif'
import astronaut from '../assets/pfp/astronaut.gif'
import biker from '../assets/pfp/biker.gif'
import bodybuilder from '../assets/pfp/bodybuilder.gif'
import chef from '../assets/pfp/chef.gif'
import clown from '../assets/pfp/clown.gif'
import construction from '../assets/pfp/construction.gif'
import cowboy from '../assets/pfp/cowboy.gif'
import farmer from '../assets/pfp/farmer.gif'
import pilot from '../assets/pfp/pilot.gif'
import prince from '../assets/pfp/prince.gif'
import zookeeper from '../assets/pfp/zookeeper.gif'
import police from '../assets/pfp/police.gif'
import doctor from '../assets/pfp/doctor.gif'

const Gallery = () => {
  const pfps = [
    { img: prince, job: 'Prince' },
    { img: biker, job: 'Biker' },
    { img: astronaut, job: 'Astronaut' },
    { img: zookeeper, job: 'Zookeeper' },
    { img: police, job: 'Police' },
    { img: clown, job: 'Clown' },
    { img: bodybuilder, job: 'BodyBuilder' },
    { img: farmer, job: 'Farmer' },
    { img: artist, job: 'Artist' },
    // { img: chef, job: 'Chef' },
    // { img: construction, job: 'Constructor' },
    { img: cowboy, job: 'Cowboy' },
    { img: doctor, job: 'Doctor' },
    // { img: fastfood, job: 'Fastfood Guy' },
    
    { img: pilot, job: 'Pilot' },
  ]

  return (
    <div id="gallery" className="w-full h-full bg-gradient-to-b from-[#5A6988] to-slate-500 pt-6 pb-16">
      <div className="flex justify-center py-10 items-center tracking-[3px] text-[200%] font-upheaval text-white">
        GALLERY
      </div>
      <div 
      // className='grid grid-cols-4 w-[75%] mx-auto py-10 gap-x-5 gap-y-3 '
      className="grid md:grid-cols-4 grid-cols-2 md:gap-y-5 gap-y-3 md:gap-x-4 gap-x-5 w-[58%] mx-auto "
      >
        {pfps &&
          pfps.map((pfp, index) => (
            <div
              key={index}
              className="w-full boder-white border-[6px] rounded-xl hover:border-sky-400 hover:shadow-md hover:shadow-sky-400 duration-300"
            >
              <img className="shadow-black  rounded-md " src={pfp.img}></img>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Gallery
