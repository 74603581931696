import { useRef, useState } from 'react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'

const Nav1 = () => {
  const location = useLocation()
  const navRoute = useNavigate()
  const [open, setOpen] = useState(false)

  const links = [
    // {name: "TWITTER", link: "https://twitter.com/"},
    // {name: "OPENSEA", link: "https://opensea.io/"},
    // {name: "DISCORD", link: "https://discord.com/"},

    { name: 'MINT', link: '/mint' },
    { name: 'TWITTER', link: 'https://twitter.com/justusweb3' },
    { name: 'OPENSEA', link: 'https://opensea.io/collection/justusnft' },
    { name: 'DISCORD', link: '' },
  ]

  const linksMintPage = [
    
    
    { name: 'OPENSEA', link: 'https://opensea.io/collection/justusnft' },
    { name: 'ETHERSCAN', link: 'https://etherscan.io/address/0xf90353c1d6dbe8bb4c0d5330fbcbfb4e004c8be0' },
  ]

  const nav = useRef(null)
  const navigate = (e) => {
    e.preventDefault()
    const offset = nav.current.offsetHeight
    const targetId = e.target.dataset.target
    const el = document.getElementById(targetId)
    window.scrollTo({
      top: el.offsetTop - offset,
      left: 0,
      behavior: 'smooth',
    })
  }
  const clickHandler = () => {
    setOpen(!open)
  }

  const navTo = (e) => {
    if (location.pathname === '/mint' && e.target.dataset.target === 'hero') {
      navRoute('/')
    } else {
      navigate(e)
      setOpen(false)
    }
  }

  return (
    <div
      ref={nav}
      className="bg-[#252b2d] md:w-full w-full fixed top-0 left-0 text-white z-10 md:h-[60px] "
    >
      <div className="md:flex items-center justify-between md:py-[0px] py-[20px] h-full md:px-10 px-7">
        <div className="font-bold lg:text-xl md:hidden text-lg md:text-lg cursor-pointer flex items-center">
          <button
            className=" hover:tracking-[8px]  duration-300 hover:font-[#2dc1f2] font-upheaval cursor-pointer text-[150%] hover:text-yellow-200"
            data-target="hero"
            onClick={(e) => navTo(e)}
          >
            JUST US
          </button>
        </div>
        <div
          className="md:text-3xl text-xl absolute right-8 top-4 cursor-pointer md:hidden"
          onClick={() => clickHandler()}
        >
          {!open ? (
            <AiOutlineMenu
              className={open && `opacity-0 duration-700`}
              size="28px"
            />
          ) : (
            <AiOutlineClose
              className={!open && `opacity-0 duration-700`}
              size="28px"
            />
          )}
        </div>
        <div
          className={`md:flex md:justify-between md:items-center md:pb-0 pb-12 absolute md:static
         bg-[#252b2d] md:bg-transparent md:z-auto z-[-1] left-0 w-full md:pl-0 pl-9 
         transition-all duration-300 ease-in ${
           open ? 'top-[68px] opacity-100' : 'top-[-490px]'
         } opacity-0 md:opacity-100`}
        >
          <span
            className=" hover:tracking-[8px] hidden md:block duration-300 hover:font-[#2dc1f2] font-upheaval text-[160%] hover:text-yellow-200"
            data-target="hero"
            onClick={(e) => navTo(e)}
          >
            JUST US
          </span>
          {location.pathname === '/' && (
            <div className="md:flex">
              {links &&
                links.map((e, index) => (
                  <div
                    key={index}
                    className="md:my-0 my-8 font-pixel12 text-md md:text-md md:mx-4 cursor-pointer duration-300 hover:tracking-[3px] tracking-[0px] hover:text-green-400"
                  >
                    <a href={e.link} target="_blank" rel="noreferer noreferrer">
                      {e.name}
                    </a>
                  </div>
                ))}
              {/* <Link
                to="/mint"
                style={{ backfaceVisibility: 'hidden' }}
                className="md:my-0 my-8 font-pixel12 text-md md:text-md md:mx-4 cursor-pointer duration-300 hover:tracking-[7px] tracking-[2px] hover:text-green-400"
              >
                MINT!
              </Link> */}
            </div>
          )}
          {location.pathname === '/mint' && (
            <div className="md:flex">
              {linksMintPage &&
                linksMintPage.map((e, index) => (
                  <div
                    key={index}
                    className="md:my-0 my-8 font-pixel12 text-md md:text-md md:mx-4 cursor-pointer duration-300 hover:tracking-[3px] tracking-[0px] hover:text-green-400"
                  >
                    <a href={e.link} target="_blank" rel="noreferer noreferrer">
                      {e.name}
                    </a>
                  </div>
                ))}
              {/* <Link
                to="/mint"
                style={{ backfaceVisibility: 'hidden' }}
                className="md:my-0 my-8 font-pixel12 text-md md:text-md md:mx-4 cursor-pointer duration-300 hover:tracking-[7px] tracking-[2px] hover:text-green-400"
              >
                MINT!
              </Link> */}
            </div>
          )}

          <div className="md:my-0 my-7">
            <ConnectButton
              chainStatus={
                location.pathname === '/mint'
                  ? { smallScreen: 'none', largeScreen: 'full' }
                  : 'none'
              }
              accountStatus={{
                smallScreen: 'full',
                largeScreen: 'full',
              }}
              showBalance={{
                smallScreen: true,
                largeScreen: true,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nav1
