import Nav1 from '../components/Nav1'
import bgju from '../assets/jubanner3.png'
import { useState, useEffect, useMemo } from 'react'
import {
  useContractRead,
  useContractWrite,
  useAccount,
  usePrepareContractWrite,
} from 'wagmi'
import { ethers } from 'ethers'
import contractABI from '../contractABI.json'
import { BiPlus, BiMinus } from 'react-icons/bi'

const Mint = () => {
  const contractAddress = '0xf90353c1D6dBe8BB4C0D5330FbcBFb4E004C8bE0';
  const _chainId = 1;
  const [counter, setCounter] = useState(1)
  const [supply, setSupply] = useState()
  const [minted, setMinted] = useState()
  const [publicPrice, setPublicPrice] = useState()
  const [ownMint, setOwnMint] = useState()
  const [maxPublicTx, setMaxPublicTx] = useState()

  const { address } = useAccount()

  /**
   * Contract reads
   */
  const maxSupply = useContractRead({
    address: contractAddress,
    abi: contractABI,
    functionName: 'maxSupply',
    chainId: _chainId,
  })

  const { data: isPaused } = useContractRead({
    address: contractAddress,
    abi: contractABI,
    functionName: 'isPaused',
    chainId: _chainId,
  })

  const maxPublic = useContractRead({
    address: contractAddress,
    abi: contractABI,
    functionName: 'maxPublicTx',
    chainId: _chainId,
  })

  const totalSupply = useContractRead({
    address: contractAddress,
    abi: contractABI,
    functionName: 'totalSupply',
    chainId: _chainId,
  })

  const _publicPrice = useContractRead({
    address: contractAddress,
    abi: contractABI,
    functionName: 'publicPrice',
    chainId: _chainId,
  })

  const totalMintedByAddress = useContractRead({
    address: contractAddress,
    abi: contractABI,
    functionName: 'totalMintedByAddress',
    chainId: _chainId,
    args: [address],
  })

  /**
   * Calculate total ETH
   */
  const calculatedTotal = useMemo(() => {
    if (!counter || !publicPrice) return '0'

    let total
    if (ownMint === 0) {
      total = (counter - 1) * publicPrice
    } else {
      total = counter * publicPrice
    }
    total = Math.round(total * 1000) / 1000
    return total.toString()
  }, [counter, publicPrice, ownMint])

  /**
   * Contract writes
   */
  const { config: publicMintConfig } = usePrepareContractWrite({
    address: contractAddress,
    abi: contractABI,
    functionName: 'publicMint',
    args: [counter],
    chainId: _chainId,
    overrides: {
      from: address,
      value: ethers.utils.parseEther(calculatedTotal),
    },
  })

  const {
    write: publicMintWrite,
    isLoading: contractLoading,
    isError: contractError,
    isSuccess: contractSuccess,
  } = useContractWrite({
    ...publicMintConfig,
    async onSettled(data, error) {
      console.log(data, error)
      await data.wait()
      const { refetch } = totalMintedByAddress
      refetch()
    },
  })
  //increase counter
  const increase = () => {
    if (counter === maxPublicTx) return
    setCounter((count) => count + 1)
    console.log(ownMint)
  }

  const decrease = () => {
    if (counter === 1 || counter < 1) return
    setCounter((count) => count - 1)
    console.log(ownMint)
  }

  // const buttonText = () => {
  //   // {contractLoading ? "Loading..." || contractError && "Error" || contractSuccess && "Success minting" ||
  //   // (isPaused ? "CONTRACT IS PAUSED" : (totalMintedByAddress < maxPublicTx ? "MINT NOW" : "CANNOT MINT MORE")) }
  //   if (!address) return "Please connect you wallet"
  //   else if (contractLoading) return "LOADING..";
  //   else if (contractError) return "ERROR";
  //   else if (isPaused) return "MINTING IS PAUSED";
  //   else if ((ownMint + counter)  >= maxPublicTx) return "CAN'T MINT MORE THAN 5";
  //   else return "MINT NOW"
  // }

  const buttonText = useMemo(() => {
    if (!address) return '...'
    else if (contractLoading) return 'LOADING..'
    else if (contractError) return 'ERROR'
    else if (isPaused) return 'MINTING IS PAUSED'
    else if (ownMint + counter > maxPublicTx)
      return `CAN'T MINT MORE THAN ${maxPublicTx}`
    else return 'MINT NOW'
  }, [
    address,
    contractLoading,
    contractError,
    isPaused,
    ownMint,
    counter,
    maxPublicTx,
  ])

  useEffect(() => {
    const { data } = maxPublic
    setMaxPublicTx(data?.toNumber())
  }, [maxPublic])

  useEffect(() => {
    const { data } = maxSupply
    setSupply(data?.toNumber())
    // console.log()
    // maxSupply(data.toNumber())
  }, [maxSupply])

  useEffect(() => {
    const { data } = totalSupply
    // console.log(data.toNumber())
    setMinted(data?.toNumber())
  }, [totalSupply])

  useEffect(() => {
    const { data } = _publicPrice
    if (data) setPublicPrice(ethers.utils.formatEther(data))
  }, [_publicPrice])

  useEffect(() => {
    const { data, isRefetching } = totalMintedByAddress
    setOwnMint(data?.toNumber())
    console.log(isRefetching, data?.toNumber())
  }, [totalMintedByAddress])

  useEffect(() => {
    console.log(calculatedTotal)
  }, [calculatedTotal])

  // useEffect(() => {
  //   // console.log(contractLoading)
  //   // console.log(contractError)
  //   // console.log(contractSuccess)
  // }, [contractLoading, contractError, contractSuccess])

  return (
    <div className="relative">
      <Nav1 />
      {/* {ownMint && console.log(ownMint)} */}
      <div
        className="absolute md:mt-[60px] mt-[60px] w-full md:w-full md:h-[calc(100vh-60px)] 
        h-[100vh] bg-cover flex justify-center items-center"
        style={{ backgroundImage: `url(${bgju})` }}
      >
        <div className="bg-white/40 w-full h-full relative flex items-center justify-center  ">
          <div className="bg-[#252b2d]/60 md:p-[8px] p-[12px] md:w-1/3 w-3/4 text-center text-white rounded-lg font-pixel12 flex items-center justify-center ">
            <div className="bg-[#252b2d]/70 md:w-full w-full h-full text-center text-white rounded-md font-pixel12 items-center uppercase py-6">
              <div className="md:text-xl text-lg">
                {isPaused ? 'MINTING IS PAUSED' : 'PUBLIC MINTING'}
              </div>
              <div className="flex justify-center items-center gap-2 my-4">
                <span className={`text-yellow-300`}>{minted}</span> Out Of{' '}
                <span className="text-lg text-green-400">{supply}</span> Minted
              </div>
              <div className="my-4">First mint free, .003 per additional mint</div>
              {address && <div className="my-4">
                {calculatedTotal === '0' ? (
                  <>
                  Total Cost:{' '}
                  <span className="text-xl">FREE</span> 
                </>
                ) : (
                  <>
                    Mint Price:{' '}
                    <span className="text-xl">{calculatedTotal}</span> ETH
                  </>
                )}
              </div>}
              <div className='mx-auto w-[70%]'>

              <div className="flex justify-center py-2 gap-x-2">
                <button
                  onClick={decrease}
                  className="bg-green-400 hover:bg-green-600 duration-300 rounded-md grow flex justify-center py-2 text-black"
                >
                  <BiMinus size={'25px'} />
                </button>
                <div className=" py-2 grow text-center text-lg bg-white rounded-md text-black">
                  {counter}
                </div>
                <button
                  onClick={increase}
                  className="bg-green-400 hover:bg-green-600 duration-300 grow rounded-md  flex justify-center py-2 text-black"
                >
                  <BiPlus size={'25px'} />
                </button>
              </div>
              <button
                disabled={ownMint + counter > maxPublicTx || isPaused}
                onClick={() => publicMintWrite?.()}
                className="bg-green-400 hover:bg-green-600 rounded-md w-full p-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {buttonText}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img alt='' src={bgju} className="absolute z-0" /> */}
    </div>
  )
}

export default Mint
