import { useState, useRef, useEffect } from 'react'
import { padStart } from 'lodash'
import { BsPlayFill, BsPauseFill } from 'react-icons/bs'
import { BiSkipNext, BiSkipPrevious } from 'react-icons/bi'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import styles from '../styles/Player.module.css'
import song1 from '../assets/songs/song-a.mp3'
import song2 from '../assets/songs/song-b.mp3'
import song3 from '../assets/songs/song-c.mp3'
import { GoMute, GoUnmute } from 'react-icons/go'

const Player = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [currentSong, setCurrentSong] = useState(0)
  const [mute, setMute] = useState(false)
  const [open, setOpen] = useState(false)

  const playlist = [song1, song2, song3]

  const audioPlayer = useRef()
  const progressBar = useRef()
  const animationRef = useRef()

  useEffect(() => {
    if (isPlaying) {
      console.log('play audio')
      audioPlayer.current.play()
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      console.log('pause audio')
      audioPlayer.current.pause()
      cancelAnimationFrame(animationRef.current)
    }
  }, [isPlaying])

  const getSongDuration = () => {
    const seconds = Math.floor(audioPlayer.current.duration)
    setDuration(seconds)
    // progressBar.current.max = seconds
  }

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60)
    const returnedMinutes = padStart(minutes, 2, '0')
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = padStart(seconds, 2, '0')
    return `${returnedMinutes}:${returnedSeconds}`
  }

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev)
  }

  const skipForward = () => {
    const current = currentSong
    if (current + 1 <= playlist.length - 1) {
      setCurrentSong(current + 1)
    } else {
      setCurrentSong(0)
    }
    setTimeout(() => {
      setIsPlaying(true)
    }, 100)
  }

  const skipBack = () => {
    const current = currentSong
    if (current > 0) {
      setCurrentSong(current - 1)
    } else {
      setCurrentSong(playlist.length)
    }
    setTimeout(() => {
      setIsPlaying(true)
    }, 100)
  }

  const handleAudioEnded = () => {
    setIsPlaying(false)
    skipForward()
  }

  const handleNextButton = () => {
    setIsPlaying(false)
    skipForward()
  }

  const handlePrevButton = () => {
    setIsPlaying(false)
    skipBack()
  }

  const toggleMute = () => {
    audioPlayer.current.muted = !audioPlayer.current.muted
    setMute(!mute)
  }

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime
    changePlayerCurrentTime()
    animationRef.current = requestAnimationFrame(whilePlaying)
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value
    changePlayerCurrentTime()
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      '--seek-before-width',
      `${(progressBar.current.value / progressBar.current.max) * 100}%`,
    )
    setCurrentTime(progressBar.current.value)
  }

  const clickHandler = () => {
    setOpen(!open)
    
  }

  return (
    <div
      className={`justify-center py-2 bg-gradient-to-r rounded-lg from-sky-500 to-indigo-500 md:w-[20wv] fixed bottom-5 right-5 transition-all ease-in-out duration-500 text-white z-10`}
    >
      {/* Progressbar */}
      <div className={`${open ? "flex" : "hidden"} px-6 pt-1 items-center justify-center space-x-2`}>
        <div className="text-sm -mt-1">{calculateTime(currentTime)}</div>
        <div className="flex-grow">
          <input
            ref={progressBar}
            type="range"
            className={styles.progressBar}
            defaultValue="0"
            onChange={changeRange}
          />
        </div>
        <div className="text-sm -mt-1">{calculateTime(duration)}</div>
      </div>

      {/* Controls */}
      <div className="flex justify-center relative">
        <div className="flex space-x-2 items-center justify-center">
          <button type="button" className="" onClick={() => handlePrevButton()}>
            {/* previous */}
            <BiSkipPrevious size={'30px'} />
          </button>
          <button type="button" className="" onClick={() => togglePlayPause()}>
            {/* Play/Pause */}
            {isPlaying ? (
              <BsPauseFill size={'30px'} />
            ) : (
              <BsPlayFill size={'30px'} />
            )}
          </button>
          <button type="button" className="" onClick={() => handleNextButton()}>
            {/* Next */}
            <BiSkipNext size={'30px'} />
          </button>
        </div>
        <button
          className={`${!open && "hidden"} absolute left-6 top-[7px]`}
          type="button"
          onClick={toggleMute}
        >
          {/* Mute/unmute */}
          {mute ? <GoMute size={'16px'} /> : <GoUnmute size={'16px'} />}
        </button>
      </div>

      {/* HTML audio */}
      <audio
        ref={audioPlayer}
        src={playlist[currentSong]}
        className="absolute bottom-0 right-0 w-0 h-0 opacity-0"
        onLoadedMetadata={getSongDuration}
        onEnded={handleAudioEnded}
        preload="metadata"
      />
      <div 
      className='absolute right-[-12.5px] top-[-12.5px] cursor-pointer bg-[#252b2d] rounded-xl'
       onClick={() => clickHandler()}>
         {open ?
          <FiMinusCircle size={"25px"} />
          :
          <FiPlusCircle size={"25px"} />
          }
          </div>
    </div>
  )
}

export default Player
