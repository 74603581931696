import React, { useState, useEffect, usueRef, useRef } from 'react'
import { useClickAway } from 'react-use'
import sec2 from '../assets/images/sec2clean.jpg'
import vending from '../assets/images/vending.png'
import sign from '../assets/images/sign.png'
import zookeper from '../assets/pfp/zookeeper.gif'
import doctor from '../assets/pfp/doctor.gif'
import fastfood from '../assets/pfp/fastfood.gif'
import police from '../assets/pfp/police.gif'
import prince from '../assets/pfp/prince.gif'
import chef from '../assets/pfp/chef.gif'
import bubbleMachine from '../assets/images/machine.png'

const Content = () => {
  const [curentImage, setCurentImage] = useState(0)
  const [openVending, setOpenVending] = useState()

  const openBubble = useRef()

  const pfps = [{ img: chef, job: 'Chef' }]

  const randImage = () => {
    if (curentImage == pfps.length - 1) {
      setCurentImage(0)
    } else {
      setCurentImage(curentImage + 1)
    }
  }

  const vendHandler = () => {
    setOpenVending(true)
  }

  useClickAway(openBubble, () => {
    setOpenVending(false)
  })

  useEffect(() => {
    const interval = setInterval(() => {
      randImage()
    }, 500)
    return () => clearInterval(interval)
  }, [curentImage])

  return (
    <div id="about" className="">
      <div className="md:w-full relative">
        <img alt="" src={sec2} className="w-full" />

        <img
          alt=""
          src={sign}
          className="absolute top-[12%] w-[43.5%] right-[12%] "
        />
        <div className="absolute top-[18%] right-[13%] ">
          <div className="text-white w-[40vw] md:h-[30vh]">
            <div className="md:grow w-full font-upheaval leading-loose text-center text-[3vw] ">
              JUST US.
            </div>
            <div className="w-full font-pixel12 leading-tight text-center mx-auto text-[1.5vw] ">
              <div>Just an NFT collection of Us.</div>
              <div>Featuring Web3 You, Web3 Me, Web3 Us.</div>
            </div>
          </div>
        </div>
        <div ref={openBubble}>
          <img
            alt=""
            src={vending}
            className="absolute top-[12%] left-[15%] w-[17%] hover:scale-[102%] transition-all duration-200"
            onClick={vendHandler}
          />
          {openVending && (
            <div
              className="absolute md:-top-[14%] -top-[40%]  left-[32.5%] z-5 lg:w-[30%] md:w-[35%]
             w-[38%] hover:scale-[103%] transition-all duration-200 "
            >
              <img alt="" src={bubbleMachine} />
            </div>
          )}
        </div>
      </div>
      <div className="bg-[#262B44] ">
        <div className=" text-white md:flex md:p-24 p-6 m-auto justify-center items-center">
          <div className="mx-auto w-full p-2 ">
            <div className=" font-upheaval w-full  uppercase text-center my-2 mb-10 text-[190%] ">
              Just Us is an NFT collection featuring Web3 Us.
            </div>
            <div className="w-full font-freepix leading-tight text-justify text-[140%] ">
              Everyone has experienced the late-night discord chats with
              friends, the euphoric feeling of finally buying your grail NFT,
              and being a part of your favorite projects. For us, we have one
              simple goal: to unite everyone and be a project where we can be
              the genuine, fun-loving, and best version of ourselves. Community
              is everything, and we hope you all join us on our journey to tell
              the story of Just Us!
            </div>
          </div>
          <div className="w-full my-8 mx-auto md:px-6 px-8 flex items-center justify-center">
            <img
              alt=""
              className="rounded-lg w-[90%]  shadow-black shadow-lg hover:rotate-2 duration-200"
              src={pfps[curentImage].img}
            ></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
